//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuthOverlay from '../AuthOverlay.vue'
export default {
  name: 'AuthCongratulations',
  components: { AuthOverlay },
  props: {
    width: {
      type: String,
      default: '583px',
    },
  },
  computed: {
    attrs() {
      return {
        width: this.width,
      }
    },
  },
}
