//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductNavigationList',
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if(this.$route.query.toReviews) {
      setTimeout(() => {
        document.querySelectorAll('.navigation.fixed .navigation--item').forEach(item => item.classList.remove('active'));
        document.querySelector('.navigation.fixed .navigation--item:last-child').classList.add('active');
      }, 0)
    }
  },
  methods: {
    onClick(prop, value) {
      document.querySelectorAll('.navigation.fixed .navigation--item').forEach(item => item.classList.remove('active'));
      this.$emit('click', prop, value)
    },
    isSelected(value) {
      return value.name === this.selected.name
    },
  },
}
