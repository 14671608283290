//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import CompanyInfoCard from '~/components/modules/Cards/CompanyInfoCard.vue'
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)
export default {
  name: 'CompanyInfo',
  components: {
    CompanyInfoCard,
    EditCompanyInfoModal: () =>
      import(
        '~/components/modules/ModalWindows/profile/company/EditCompanyInfoModal.vue'
      ),
  },
  mixins: [validationMixin],
  data() {
    return {
      card: null,
      form: {
        companyName: '',
        nip: '',
        email: '',
        phone: '',
        code: '',
        voivodeship: '',
        city: '',
        street: '',
        building: '',
        apartment: '',
      },
    }
  },
  validations: {
    form: {
      companyName: { required, minLength: minLength(2), maxLength: maxLength(90) },
      nip: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
      email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
      phone: { required, minLength: minLength(10) },
      code: { required },
      voivodeship: { required, minLength: minLength(2), maxLength: maxLength(100) },
      city: { required, minLength: minLength(2), maxLength: maxLength(100) },
      street: { required, minLength: minLength(2), maxLength: maxLength(100) },
      building: { required, maxLength: maxLength(10) },
      apartment: { maxLength: maxLength(10) },
    },
  },
  mounted() {
    return this.fetchCompanyInfo()
  },
  methods: {
    fetchCompanyInfo() {
      return this.$api.user.getCompanyInfo().then(({ data }) => {
        this.card = {
          companyName: data.name,
          nip: data.nip,
          email: data.email,
          phone: data.phone,
          address: data.address,
        }
      this.$store.commit('setCompanyName', this.card.companyName)
      })
    },
    onChange(prop, val) {
      this.form[prop] = val
    },

    onOpenModal() {
      this.form = {
        companyName: this.card.companyName,
        nip: this.card.nip,
        email: this.card.email,
        phone: this.card.phone,
        ...this.card.address,
        voivodeship: this.card.address.voivodeship,
        street: this.card.address.street,
        city: this.card.address.city
      }
      this.$refs.modal.onOpen()
    },
  },
}
