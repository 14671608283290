//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'hooper/dist/hooper.css'
import Counter from '~/components/UI/Counter.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
export default {
  name: 'ProductDescriptionsActions',
  components: {
    Counter,
  },
  props: {
    counterVisible: {
      type: Boolean,
      default: true,
    },
    btnEventsProductVisible: {
      type: Boolean,
      default: true,
    },
    entity: {
      type: String,
      default: '',
    },
    heightBtnIcon: {
      type: String,
      default: '48px',
    },
    widthBtnIcon: {
      type: String,
      default: '48px',
    },
    radiusBtnIcon: {
      type: String,
      default: '0%',
    },
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      favorite_id: null,
      compare_id: null,
      textTooltip: '',
      textTicket: '',
      count: 1,
    }
  },
  fetch() {
    this.compare_id = this.$props.product.compare_id
    this.favorite_id = this.$props.product.favorite_id
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    isAvailable() {
      return this.product.status === 1
    },
    nameBtnProduct() {
      // if(this.$props.product.in_cart) {
      //   return this.$t('itemInCart')
      // }
      return !this.isAuth ? this.$t('logInToSeeThePrice') : !this.isAvailable ? this.$t('notifyMeWhenAvailable') : this.$t('addToCart')
    },
    buttonType() {
      if (!this.isAuth) return 'secondary'
      if (!this.isAvailable) return 'secondary'
      return 'primary'
    },
    icon() {
      return {
        icon: !this.isAuth
          ? 'user'
          : !this.isAvailable
            ? 'mail'
            : 'add-to-basket',
        color: !this.isAuth ? 'none' : !this.isAvailable ? 'none' : '#ffffff',
        stroke: !this.isAuth
          ? this.$color.dark
          : !this.isAvailable
            ? this.$color.dark
            : 'none',
        size: !this.isAuth ? '16px' : !this.isAvailable ? '24px' : '24px',
      }
    },
    compareStroke() {
      return this.compare_id ? this.$color.primary : this.$color.dark
    },
    favoriteStroke() {
      return this.favorite_id ? this.$color.destructiveRed : this.$color.dark
    },
    favoriteFill() {
      return this.favorite_id ? this.$color.destructiveRed : 'none'
    },
    getEntity() {
      const type = /product/
      const patch = this.$route.path
      return this.$props.entity || (type.test(patch) ? 'product' : 'set')
    },
    compareItems () {
      return this.$store.getters['compares/getItems']
    },
    isInCompareList () {
      let isIn
      for(let item of this.compareItems) {
        if (item.slug === this.product.slug) {
          isIn = true
          break
        }
      }

      return isIn ? this.$color.primary : this.$color.dark
    }
  },
  watch: {
    textTooltip() {
      setTimeout(() => {
        this.textTooltip = ''
      }, 4000)
    },
    textTicket() {
      setTimeout(() => {
        this.textTicket = ''
      }, 4000)
    },
    product() {
      this.compare_id = this.$props.product.compare_id
      this.favorite_id = this.$props.product.favorite_id
    },
  },
  methods: {
    async clickBtn() {
      const payload = {
        entity: this.getEntity,
        slug: this.$props.product.slug,
        quantity: this.count
      }
      const product = {
        name: this.$props.product.name,
        picture: this.$props.product.files[0].url,
        slug: this.$props.product.slug,
        price: this.$props.product.sum,
        qty: this.$props.product.quantity,
      }
      if(this.nameBtnProduct === this.$t('addToCart')) {
        try {
          await this.$api.cart.addItemCart(payload)
          this.product.in_cart = true
          this.$store.commit('cart/SET_CART_PRODUCT', product)
        } catch (error) {
          // console.log(error)
        }
      }

      if(this.nameBtnProduct === this.$t('notifyMeWhenAvailable')) {
        const params = {
          type: 1,
          entity: this.getEntity,
          code: this.$props.product.code,
        }

        try {
          await this.$api.tickets.addTicket(params)
          this.textTicket = this.$t('yourRequestHasBeenSent')
        } catch (error) {}
      }
    },
    async selectCompare() {
      this.selectProduct = this.$props.product.slug
      const  product = this.$props.product
      const payload = {
        entity: this.getEntity,
        slug: product.slug,
      }
      try {
        if(!this.compare_id) {
          const product = {
            files: this.$props.product.files,
            name: this.$props.product.name,
            slug: this.$props.product.slug,
          }
          const res = await this.$api.compare.addCompare(payload)
          this.$store.commit('compares/SET_COMPARE', product)
          this.compare_id = res.data.id
        } else {
          this.$store.commit('compares/REMOVE_COMPARE', this.$props.product.slug)
          await this.$api.compare.deleteCompare(this.compare_id)
          this.compare_id = null
        }
      } catch (error) {
        this.textTooltip = this.$t('addingCompareItemCategoryMismatch')
        // console.log(error)
      }
    },
    async selectFavorite() {
      const  product = this.$props.product
      const payload = {
        entity: this.getEntity,
        slug: product.slug,
      }
      try {
        if(!this.favorite_id) {
          const res = await this.$api.favorites.addFavorites(payload)
          this.favorite_id = res.data.id
        } else {
          await this.$api.favorites.deleteFavorites(this.favorite_id)
          this.favorite_id = null
        }
      } catch (error) {
        // console.log(error)
      }
    },
    handleCount(value) {
      this.count = value
    },
  },
}
