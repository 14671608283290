//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userMenu from '~/constants/user-menu.const'
import { LOGOUT } from '~/store/auth/types.auth'
import { GET_USER } from '~/store/user/types.user'
export default {
  name: 'NavSide',
  data() {
    return {
      links: userMenu,
    }
  },
  computed: {
    user() {
      return this.$store.getters[`user/${GET_USER}`]
    },
    userName() {
      if (!this.user) return ''
      return `${this.user.first_name} ${this.user.last_name}`
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch(`auth/${LOGOUT}`)
    },
  },
}
