//
//
//
//
//
//
//
//

export default {
  name: 'SkeletonRect',
  props: {
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
  },
}
