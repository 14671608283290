//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import ProductsSlider from '~/components/modules/Sliders/ProductsSlider.vue'
import RectSkeleton from '~/components/skeleton/rect'
import SkeletonProductCard from '~/components/skeleton/SkeletonProductCard'

export default {
  name: 'MainProducts',
  components: {ProductsSlider, SkeletonWrapper, RectSkeleton, SkeletonProductCard},
  props: {
    section: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      items: [],
      loading: true,
    }
  },
  fetch() {
    return this.fetchProducts()
  },

  methods: {
    fetchProducts() {
      const params = {
        per_page: this.perPage || 10,
      }
      return this.$api.main
        .productsList({id: this.section.id, params, type: this.type})
        .then(({data}) => {
          this.items = data.map((slide, i) => ({
            ...slide,
            id: i,
            labels: slide.labels.map((label) => ({
              ...label,
              color: label.color.toLowerCase(),
            })),
            variations: slide.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].preview_url : '',
              }
            }),
          }))
          this.loading = false
        })
    },
  },
}
