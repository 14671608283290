//
//
//
//
//
//
//
//

export default {
  name: 'Point',
  props: {
    width: {
      type: String,
      default: '10px',
    },
    height: {
      type: String,
      default: '10px',
    },
  },
}
