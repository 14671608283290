//
//
//
//
//
//
//

export default {
  name: 'Banner',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '200px',
      required: true,
    },
    countPoints: {
      type: Number,
      default: 3,
    },
  },
}
