//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import ProductAuthBlock from './components/ProductAuthBlock.vue'
import MediaFiles from '~/components/UI/MediaFiles.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'
import Delivery from '~/components/UI/Delivery.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
export default {
  name: 'ProductDescriptions',
  components: {
    ProductAuthBlock,
    MediaFiles,
    ProductPrice,
    Delivery,
    ProductStatus: () => import('~/components/UI/ProductStatus.vue'),
    PicturesRowList: () => import('~/components/UI/PicturesRowList.vue'),
    ProductDescriptionsActions: () =>
      import('./components/ProductDescriptionsActions.vue'),
    ProductVariationsModal: () =>
      import('~/components/modules/ModalWindows/ProductVariationsModal.vue'),
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    starColor() {
      return this.product.reviews_count > 0 ? '#F7B100' : this.$color.grey
    },
    hasPrice() {
      if (!Object.keys(this.product).length) return false
      if (!this.isAuth || Array.isArray(this.product.price_type)) return false
      return !!Number(this.product.price_type.netto)
    },
    variations() {
      if (!this.product.variations) {
        return []
      }
      return Object.keys(this.product.variations).filter(
        (variation) => variation !== 'primary',
      )
    },
    pictureList() {
      const list = {
        items: [],
        itemH: '60px',
        itemW: '60px',
        offset: '20px',
        hasActive: false,
        sliceCount: 6,
      }
      if (this.product.variations?.primary) {
        list.items = this.product.variations[this.product.variations.primary]
      }
      return list
    },
    price() {
      if (!this.hasPrice) {
        return {
          price: false,
          discountPrice: false,
          discount: '0',
        }
      }
      const price = Number(this.product.price_type.netto)
      const discountPrice = Number(this.product.price_type.discount_netto)
      const discount = Math.ceil((discountPrice * 100) / price)
      return {
        price: `${price} zl`,
        discountPrice: discountPrice && `${discountPrice} zl`,
        discount: `-${discount} %`,
      }
    },
    reviewsText() {
      return this.product.reviews_count === 1 ?
        this.$t('review').toLowerCase() :
        this.$t('reviews').toLowerCase()
    }
  },

  methods: {
    link(slug) {
      return { name: `${this.type === 1 ? 'product' : 'set'}-id`, params: { id: slug } }
    },
    onSelectVariation(type) {
      const item = {
        title: type,
        items: [],
      }
      item.items = this.product.variations[type].map((variation) => ({
        ...variation,
        picture: variation.files[0].url,
        name: variation.name,
      }))
      this.$refs['product-modal'].onOpen(item)
    },

    praseVariations(item) {
      return item.map((variation) => ({
        picture: variation.file,
        slug: variation.slug,
        name: variation.product_name,
        price: '',
      }))
    },
  },
}
