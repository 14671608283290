//
//
//
//
//
//
//
//
//

export default {
  name: 'MainHeaderSection',
  props: {
    title: {
      type: String,
      default: '',
      require: true,
    },
    link: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
}
