//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import AuthOverlay from '../AuthOverlay.vue'
import validationWatcher from '~/mixins/validationWatcher'
import PasswordInput from '~/components/UI/PasswordInput.vue'

const containsPasswordRequirement = helpers.regex('The password requires an uppercase, lowercase, number', /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
export default {
  name: 'AuthNewPass',
  components: { AuthOverlay, PasswordInput },
  mixins: [validationMixin, validationWatcher],
  data() {
    return {
      form: {
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('createNewPassword'),
        width: '430px',
      }
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(36),
        containsPasswordRequirement,
      },
      confirmPassword: {
        errorPasswordConfirmation: sameAs('password'),
      },
    },
  },
  methods: {
    onSend() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      const params = {
        password: this.form.password,
        password_confirmation: this.form.confirmPassword,
        token: this.$route.query.token,
      }

      this.$api.auth
        .recoverPassword(params)
        .then(() => this.$router.push({ path: 'auth', query: { type: 'login' } }))
        .catch(() => {
        })
        .finally(() => this.setValidationSettings(false))
    },
  },
  created() {
    this.$api.auth
      .testRecoverPassword({ token: this.$route.query.token })
      .then(() => {})
      .catch(() => this.$router.push({ path: 'reset-password-again'}))
  }
}
