//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductAuthBlock',
  methods: {
    onAuth(type) {
      this.$root.$emit('handleAuthModal', type)
    },
  },
}
