//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import CompanyUsersModal from '~/components/modules/ModalWindows/profile/company/CompanyUsersModal.vue'
import Table from '~/components/UI/Table.vue'
import Paginations from '~/components/UI/Paginations.vue'
import { IS_MOBILE } from '~/store/device/type.device'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)
export default {
  components: {
    Table,
    Paginations,
    CompanyUsersModal,
  },
  mixins: [validationMixin],
  data() {
    return {
      users: [],
      fields: [
        {
          key: 'name',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('labelEmail'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('phoneNumber'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          _width: '100px',
        },
      ],
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      total: 0,
      pages: 0,
      sort: {
        orderBy: {},
        per_page: 10,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  validations: {
    form: {
      first_name: { required, minLength: minLength(3), maxLength: maxLength(32), wrongFormat },
      last_name: { required, minLength: minLength(3), maxLength: maxLength(32), wrongFormat },
      phone: { required, minLength: minLength(10) },
      email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
    },
  },
  fetch() {
    return this.fetchUsers()
  },
  computed: {
    ...mapState({
      base_user: state => state.user.user.base_user,
    }),
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  methods: {
    fetchUsers() {
      const params = {
        orderBy: this.sort.orderBy,
        per_page: +this.sort.per_page,
        page: +this.sort.page,
      }
      return this.$api.user.getCompanyUsers(params).then((payload) => {
        const { data, meta } = payload
        const usersData = data.map(item => ({
          email: item.email,
          first_name: item.first_name,
          id: item.id,
          last_name: item.last_name,
          phone: item.phone,
          status: item.status,
          name: `${item.first_name} ${item.last_name}`
        }))
        this.users = usersData
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      })
    },
    onChange(prop, val) {
      this.form[prop] = val
    },
    onSort(value) {
      this.sort.orderBy = {
        [value.key]:
          this.sort.orderBy[value.key] &&
          this.sort.orderBy[value.key] === 'DESC'
            ? 'ASC'
            : 'DESC',
      }
      this.fetchUsers()
    },
    onClick(value, type) {
      if(this.base_user) {
        switch (type) {
        case 'Edit':
          this.setForm(value)
          break
        case 'Create':
          this.setForm(value)
          break

        default:
          break
        }
        this.$refs.modal.handleClick(type)
      }
    },
    setForm(form = {}) {
      this.form = {
        first_name: '',
        last_name: '',
        second_name: '',
        email: '',
        phone: '',
        ...form,
      }
    },

    selectOffset(value) {
      this.sort.per_page = value
    },

    onSelectPage(value) {
      this.sort.page = value
      this.fetchUsers()
    },
  },
}
