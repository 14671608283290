//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      products: state => state.compares.items,
    }),
    disabledCompare() {
      return this.products.length === 1
    },
  },
  methods: {
    async removeProduct(slug, id) {
      try {
        this.$store.commit('compares/REMOVE_COMPARE', slug)
        await this.$api.compare.deleteCompare(id)
      } catch (error) {
        // console.log(error)
      }
    },
    async removeAll() {
      try {
        await this.$api.compare.deleteCompareAll()
        this.$store.commit('compares/REMOVE_ALL')
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
