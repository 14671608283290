//
//
//
//
//
//

import RectSkeleton from '~/components/skeleton/rect'

export default {
  name: 'SkeletonRoomCard',
  components: {
    RectSkeleton,
  },
  props: {
    height: {
      type: String,
      default: '500px',
    },
    width: {
      type: String,
      default: '24%',
    },
  },
}
