//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import AuthOverlay from '../AuthOverlay.vue'
export default {
  name: 'AuthLogin',
  components: { AuthOverlay },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    attrs() {
      return {
        title: this.$t('registration'),
        width: '430px',
      }
    },
  },
  methods: {},
}
