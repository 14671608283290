//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Banner from '~/components/skeleton/banner'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import Point from '~/components/skeleton/point'
export default {
  name: 'MainBannerSlider',
  components: {
    Point,
    SkeletonWrapper,
    Slider: () => import('~/components/UI/Slider.vue'),
    DarkArrows: () => import('~/components/UI/SliderArrows/DarkArrows.vue'),
    Banner,
  },
  data() {
    return {
      slides: [],
      loading: true,
      settings: {
        infiniteScroll: true,
        playSpeed: 5000,
        hoverPause: false,
      },
    }
  },
  mounted() {
    return this.fetchBanners()
  },
  methods: {
    fetchBanners() {
      return this.$api.main.bannersList().then(({data}) => {
        this.slides = data.slides.map((slide) => {
          return {
            src: `${this.$config.baseURL}/files${slide.file_url}`,
            name: slide.name || 'slide',
            alt: slide.alt,
            link: slide.link,
            open_in_new_tab: slide.open_in_new_tab,
          }
        })
        this.loading = false
      })
    },
    typeTarget(typeTub) {
      return typeTub ? '_blank' : '_self'
    },
  },
}
