//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CategoriesSlider from '~/components/modules/Sliders/CategoriesSlider.vue'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import RectSkeleton from '~/components/skeleton/rect'
import SkeletonProductCard from '~/components/skeleton/SkeletonProductCard'

export default {
  name: 'MainCategories',
  components: {SkeletonProductCard, SkeletonWrapper, CategoriesSlider, RectSkeleton},
  props: {
    section: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    perPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
      loading: true,
      linkName: `${this.$t('all')} ${this.$t('categories')}`
    }
  },
  mounted() {
    return this.fetchCategories()
  },

  methods: {
    fetchCategories() {
      const params = {
        per_page: this.perPage || 10,
      }
      return this.$api.main
        .categoriesList({id: this.section.id, params})
        .then(({data}) => {
          this.items = data.map((slide) => ({
            ...slide,
            title: slide.title,
            src: slide.file_preview_url,
            productsCount: `${slide.products_count + slide.sets_count} ${this.$t('products')}`,
          }))
          this.loading = false
        })
    },
  },
}
