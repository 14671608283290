//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import AuthOverlay from '../AuthOverlay.vue'
import validationWatcher from '~/mixins/validationWatcher'
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)

export default {
  name: 'AuthLogin',
  components: { AuthOverlay },
  mixins: [validationMixin, validationWatcher],
  data() {
    return {
      form: {
        email: '',
      },
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('restorePassword'),
        width: '430px',
      }
    },
  },
  validations: {
    form: {
      email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
    },
  },
  methods: {
    toLogin() {
      this.$router.push({ path: 'auth', query: { type: 'login' } })
    },
    onSend() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      const params = {
        email: this.form.email,
        email_confirmation: this.form.email,
      }
      this.$api.auth
        .forgot(params)
        .then(() => this.$emit('click', this.form.email, 'restore'))
        .catch(() => {})
        .finally(() => this.setValidationSettings(false))
    },
  },
}
