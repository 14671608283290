//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RoomsSlider from '~/components/modules/Sliders/RoomsSlider.vue'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import RectSkeleton from '~/components/skeleton/rect'
import SkeletonRoomCard from '~/components/skeleton/SkeletonRoomCard'
export default {
  components: { RoomsSlider, SkeletonWrapper, RectSkeleton, SkeletonRoomCard },
  props: {
    section: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    perPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
      loading: true,
      linkName: `${this.$t('all')} ${this.$t('rooms')}`
    }
  },
  mounted() {
    return this.fetchRooms()
  },
  methods: {
    fetchRooms() {
      const params = { per_page: this.perPage || 10 }
      return this.$api.main
        .roomsList({ id: this.section.id, params })
        .then(({ data }) => {
          this.items = data.map((slide) => ({
            ...slide,
            src: slide.files[0].url,
            productsCount: `${slide.items_count} ${this.$t('products')}`,
          }))
          this.loading = false
        })
    },
  },
}
