//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength } from 'vuelidate/lib/validators'
import CompanyDeliveryAddressCard from '~/components/modules/Cards/CompanyDeliveryAddressCard.vue'
import CompanyAddressModal from '~/components/modules/ModalWindows/profile/company/CompanyAddressModal.vue'
import { IS_MOBILE } from '~/store/device/type.device'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
export default {
  name: 'CompanyAddresses',
  components: { CompanyDeliveryAddressCard, CompanyAddressModal },
  mixins: [validationMixin],
  data() {
    return {
      cards: [],
      form: {
        company_name: '',
        apartment: '',
        building: '',
        city: '',
        code: '',
        description: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        voivodeship: '',
        is_default: false,
      },
    }
  },
  validations: {
    form: {
      building: { required, maxLength: maxLength(10) },
      apartment: { maxLength: maxLength(10) },
      city: { required, minLength: minLength(3), maxLength: maxLength(100) },
      code: { required },
      description: { required },
      first_name: { required, minLength: minLength(3), maxLength: maxLength(90), wrongFormat },
      last_name: { required, minLength: minLength(3), maxLength: maxLength(90), wrongFormat },
      company_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
      street: { required, minLength: minLength(3), maxLength: maxLength(100) },
      voivodeship: { required, minLength: minLength(3), maxLength: maxLength(100) },
      phone: { required, minLength: minLength(10) },
    },
  },
  computed: {
    ...mapState({
      base_user: state => state.user.user.base_user,
    }),
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  mounted() {
    this.fetchCompanyAddresses()
  },
  methods: {
    fetchCompanyAddresses() {
      return this.$api.user.getCompanyDeliveryAddresses().then(({ data }) => {
        this.cards = data
      })
    },

    onChange(prop, val) {
      this.form[prop] = val
    },

    onOpenModal(value, id = 0) {
      const item = this.cards.find((card) => card.id === id)
      switch (value) {
      case 'Create':
        this.setInitialForm()
        break
      case 'Edit':
        this.setInitialForm(item)
        break

      default:
        break
      }
      this.$refs.modal.handleClick(value)
    },
    setInitialForm(form = {}) {
      this.form = {
        apartment: '',
        building: '',
        code: '',
        description: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        is_default: false,
        ...form,
        voivodeship: form.voivodeship,
        city: form.city,
        street: form.street,
      }
    },
  },
}
